import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["url", "form", "modal", "backdrop", "domain"];
  static values = {
    applicationId: String,
    token: String,
    render: String,
    domain: String,
  }

  get config() {
    this._config = {
      applicationId: this.applicationIdValue,
      token: this.tokenValue,
      prefilledDomain: this.domainTarget.value,
    };

    if (this.domainIsSubdomain) {
      this._config.dnsRecords = [
        {
          type: "CNAME",
          host: this.subdomain,
          value: this.renderValue,
          ttl: 300
        },
      ];
    }
    else {
      this._config.dnsRecords = [
        {
          type: "A",
          host: "@",
          value: '216.24.57.1',
          ttl: 300
        },
      ];
    }

    return this._config;
  }

  get customDomainFlow() {
    return this.typeValue == 'custom-domain'
  }

  get subdomain() {
    let sub = this.domainTarget.value.split('.');
    sub.pop()
    sub.pop()
    return sub.join('.')
  }

  connect() {
    window.addEventListener('onEntriClose', this.handleOnEntriClose.bind(this));
  }

  show(event) {
    event.preventDefault();
    event.stopPropagation();

    this.backdropTarget.classList.add('show');
    this.backdropTarget.style.display = 'block';

    this.modalTarget.classList.add('show');
    this.modalTarget.style.display = 'block';
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();

    this.backdropTarget.style.display = 'none';
    this.modalTarget.style.display = 'none';
  }

  proceed(event) {
    event.preventDefault();
    event.stopPropagation();

    this.backdropTarget.style.display = 'none';
    this.modalTarget.style.display = 'none';

    window.entri.showEntri(this.config);
  }

  get domainIsSubdomain() {
    return this.domainTarget.value.split('.').length > 2
  }

  handleOnEntriClose(event) {
    this.createCustomDomain(event)    
  }

  createCustomDomain(event) {
    if (event.detail.success && event.detail.lastStatus == 'FINISHED_SUCCESSFULLY') {
      this.urlTarget.value = event.detail.domain;
      this.formTarget.requestSubmit(); 
    }
  }

  disconnect() {
    window.removeEventListener('onEntriClose', this.handleOnEntriClose);
  }
}
