import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "elementsToDisable"]

  submit() {
    this.elementsToDisableTargets.forEach(element => element.disabled = true)

    if (this.hasFormTarget) {
      this.formTarget.submit()
    } {
      this.element.requestSubmit()
    }
  }

  submitForm() {
    Turbo.navigator.submitForm(this.formTarget)
  }

  // Use submitFormDebounce() for forms that get submitted on multiple keystroke.
  submitFormDebounce(event) {
     const time = event.params.debounceTime || 500

     clearTimeout(this.timeout)
     this.timeout = setTimeout(() => {
         Turbo.navigator.submitForm(this.formTarget)
       }, time)
   }
}
