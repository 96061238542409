import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "elementToShow"
  ];

  showElement(event) {
    const tr = event.srcElement.closest('tr');
    const submitButton = tr.getElementsByClassName('count-on-hand-submit')[0];
    submitButton.classList.remove('invisible');
  }
}
