import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'country',
    'registrationTypesForm',
    'registrationTypeRadio'
  ]

  connect() {
    super.connect()
    this.changeCountry()
  }

  changeCountry(_) {
    const countryIso = this.countryTarget.value

    if (countryIso == 'GB' || !countryIso) {
      this.registrationTypesFormTarget.classList.add('hidden')
      this.registrationTypeRadioTargets.forEach(radio => radio.checked = false)
    } else {
      this.registrationTypesFormTarget.classList.remove('hidden')
    }
  }
}
