import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['value', 'templateTag', 'templateVendor', 'templateCategory', 'templateReleaseDate', 'templateSale']

  replace(event) {
    const inputString = event.target.value;
    const parts = inputString.split("::");
    const lastPart = parts[parts.length - 1];

    this.valueTarget.innerHTML = this[`template${lastPart}Target`].innerHTML
  }
}
