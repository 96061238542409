import jquery from 'jquery'
import 'bootstrap'
import 'popper.js'
import { lockScroll, unlockScroll } from './scroll_lock'

const $ = jquery

window.bootstrapJQuery = jquery

const initTooltips = () => {
  $('.with-tip').each(function() {
    $(this).tooltip()
  })

  $('.with-tip').on('show.bs.tooltip', function(event) {
    if (('ontouchstart' in window)) {
      event.preventDefault()
    }
  })
}

const removeTooltips = () => {
  $('.with-tip').each(function() {
    $(this).tooltip('dispose')
  })
}

// ugly JS copied from a tutorial :)
const initOffcanvas = () => {
  $(document).on('click', '.pull-bs-canvas-right, .pull-bs-canvas-left', function(){
		$('body').prepend('<div class="bs-canvas-overlay bg-dark position-fixed w-100 h-100"></div>');
		if($(this).hasClass('pull-bs-canvas-right'))
			$('.bs-canvas-right').addClass('mr-0');
		else
			$('.bs-canvas-left').addClass('ml-0');
		return false;
	});

	$(document).on('click', '.bs-canvas-close, .bs-canvas-overlay', function(){
		var elm = $(this).hasClass('bs-canvas-close') ? $(this).closest('.bs-canvas') : $('.bs-canvas');
		elm.removeClass('mr-0 ml-0');
		$('.bs-canvas-overlay').remove();
		return false;
	});
}

const hideOffcanvas = () => {
  $('.bs-canvas').removeClass('mr-0 ml-0');
  $('.bs-canvas-overlay').remove();
}

const initNavs = () => {
  document.querySelectorAll('#settings-nav .nav-link').forEach((element) => {
    element.addEventListener('click', () => {
      element.parentElement.parentElement.querySelectorAll('.nav-link.active').forEach((element) => {
        element.classList.remove('active')
      })
    })
  })
}

$('.modal').on('show.bs.modal', lockScroll)
$('.modal').on('hide.bs.modal', unlockScroll)

document.addEventListener("turbo:click", removeTooltips)
document.addEventListener("turbo:load", initTooltips)
document.addEventListener("turbo:load", initOffcanvas)
document.addEventListener("turbo:load", initNavs)
document.addEventListener("turbo:click", hideOffcanvas)
document.addEventListener('turbo:frame-render', initTooltips)
